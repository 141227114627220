import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import SEO from '../components/Seo';
import { ContactUsHero, ContactUsLocations, ContactUsForm } from '../components/ContactUs';
import Layout from '../components/Layout';

const Index = ({ location }) => {
  useEffect(() => {
    window.scrollTo(0, 0);
    if (location.hash) {
      const hash = document.getElementById(location.hash.replace('#', ''));
      hash.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
      });
    }
  }, []);
  return (
    <>
      <SEO title="Contact us" description="Get in touch" location={location} />
      <Layout location={location}>
        <ContactUsHero />
        <ContactUsLocations />
        <ContactUsForm />
      </Layout>
    </>
  );
};

Index.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }).isRequired,
};

export default Index;
