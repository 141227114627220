import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Container from 'react-bootstrap/Container';
import { GatsbyImage } from 'gatsby-plugin-image';
import { ContactForm } from '../../Common';
import './ContactUsForm.scss';

const ContactUsForm = () => {
  const data = useStaticQuery(
    graphql`{
  file(relativePath: {eq: "contact-form-map.png"}) {
    ...fullWidthImgHD
  }
}
`,
  );
  return (
    <Container id="contact-form" className="contact-us-form-container position-relative d-flex flex-column mt-5 p-0" fluid>
      <GatsbyImage
        image={data.file.childImageSharp.gatsbyImageData}
        title="Contact Us Form Map"
        alt="Contact Us image"
      />
      <ContactForm className="cu-contact-form pt-3 pt-md-1" btnTitle="Contact Us" />
    </Container>
  );
};

export default ContactUsForm;
