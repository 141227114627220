import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Container from 'react-bootstrap/Container';
import { GatsbyImage } from 'gatsby-plugin-image';
import './ContactUsHero.scss';

const ContactUsHero = () => {
  const data = useStaticQuery(
    graphql`{
  file(relativePath: {eq: "contact-us-hero.png"}) {
    ...fullWidthImgHD
  }
}
`,
  );

  return (
    <Container className="contact-us-hero-container position-relative d-flex flex-column justify-content-center align-items-center p-0" fluid>
      <GatsbyImage
        image={data.file.childImageSharp.gatsbyImageData}
        title="Contact Us Hero"
        alt="Contact Us Hero image"
      />

      <div className="title-container d-flex flex-column position-absolute justify-content-center text-center">
        <div className="hero-text d-flex position-relative fw-bold">
          <p className="me-lg-5 mt-3 mt-sm-5">Let&apos;s Build together!</p>
        </div>
      </div>
    </Container>
  );
};

export default ContactUsHero;
