import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Container from 'react-bootstrap/Container';
import { GatsbyImage } from 'gatsby-plugin-image';
import './ContactUsLocations.scss';
import { FaRegEnvelopeOpen, FaMapMarkerAlt } from 'react-icons/fa';

const ContactUsLocations = () => {
  const data = useStaticQuery(
    graphql`{
  file(relativePath: {eq: "locations-map.png"}) {
    ...fullWidthImgHD
  }
}
`,
  );

  return (
    <Container className="contact-us-locations-container d-flex flex-column justify-content-center align-items-center" fluid>
      <div className="title-text d-flex fw-bold my-3 my-md-5">
        <p>Build Locations</p>
      </div>
      <div className="map-cards-container d-flex flex-column position-relative">
        <div className="location-card montana-card d-flex">
          <div className="map-icon-container d-flex justify-content-center align-items-center pb-4">
            <FaMapMarkerAlt className="map-icon" color="#F2B91B" size="3.5em" />
          </div>
          <div className="d-flex flex-column justify-content-center mt-2">
            <h5 className="fw-bold mb-0">Montana</h5>
            <p className="mb-0 mt-1">Bozeman, Montana, US</p>
            <div className="mail d-flex mt-2 align-items-center">
              <FaRegEnvelopeOpen className="me-1" color="#04BCCA" />
              <p className="mb-0">info@build.global</p>
            </div>
          </div>
        </div>
        <GatsbyImage
          image={data.file.childImageSharp.gatsbyImageData}
          title="Build Locations"
          alt="Build locations map"
        />
        <div className="location-card cr-card d-flex">
          <div className="map-icon-container d-flex justify-content-center align-items-center pb-4">
            <FaMapMarkerAlt className="map-icon" color="#F2B91B" size="3.5em" />
          </div>
          <div className="d-flex flex-column justify-content-center mt-2">
            <h5 className="fw-bold mb-0">San José</h5>
            <p className="mb-0 mt-1">Lindora, San José, Costa Rica</p>
            <div className="mail d-flex mt-2 align-items-center">
              <FaRegEnvelopeOpen className="me-1" color="#04BCCA" />
              <p className="mb-0">info@build.global</p>
            </div>
          </div>
        </div>
      </div>
    </Container>
  );
};

export default ContactUsLocations;
